import {Component, Input} from '@angular/core';
import {LinkifyPipe} from "../../pipes/linkify.pipe";
import {HeadlineComponent} from "../headline/headline.component";
import {LinkComponent} from "../link/link.component";
import {TranslatePipe} from "../../pipes/translate.pipe";
import {DatePipe, NgIf, NgOptimizedImage} from "@angular/common";
import {ContentComponent} from "../content/content.component";
import {TYPE} from "../../types/types";
import {PillComponent} from "../pill/pill.component";
import {IconBadgeComponent} from "../icon-badge/icon-badge.component";
import {AvatarComponent} from "../avatar/avatar.component";

@Component({
  selector: 'app-notification',
  standalone: true,
    imports: [
        LinkifyPipe,
        HeadlineComponent,
        LinkComponent,
        TranslatePipe,
        NgOptimizedImage,
        NgIf,
        ContentComponent,
        DatePipe,
        PillComponent,
        IconBadgeComponent,
        AvatarComponent
    ],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {
  @Input() public notification: TYPE.Notification = {} as TYPE.Notification;

  public setRead(): void {
    // TODO: Implement this method
  }

}
