<div class="card shadow-sm w-border rounded-3 mb-2 notifications-card">
  <div class="card-body">
    <div class="d-flex-column">
      <div class="d-flex align-items-start">
        <app-avatar [image]="notification.image" [name]="notification.name" [subTitle]="notification.message" [date]="notification.created_at" [type]="'large'"></app-avatar>

        <div class="col ps-2">
          <div class="d-flex flex-wrap align-items-center">
            <app-headline [type]="'notification'" [title]="notification.name"></app-headline>
            <app-content [style]="'notificationDate'" [cssClass]="'mb-0'">{{ 'POSTEDAT' | translate }} {{ notification.created_at | date : 'medium'}}</app-content>
          </div>

          <p class="fs-small text-muted my-1" [innerHTML]="notification.message"></p>

          <div class="row align-items-center gx-2">
            <div class="col-auto d-flex align-items-center">
              <span class="badge bg-primary rounded-pill">{{ notification.type | translate }}</span>
              <!--<app-pill [style]="'notification'">{{ notification.type | translate }}</app-pill>-->
              <button class="e-btn e-success e-verysmall ms-2 me-1"><span class="fs-verysmall">Accept</span></button>
              <button class="e-btn e-danger e-verysmall"><span class="fs-verysmall">Reject</span></button>
            </div>
            <div class="col-auto flex-grow-1 text-end" *ngIf="notification.type !== 'chat'">
              <div class="dropdown">
                <div id="notifications-recent" data-bs-toggle="dropdown" aria-expanded="false">
                  <app-icon-badge [style]="'notification'" [icon]="'more_horiz'" [screenReaderText]="'SRMOREOPTIONS' | translate"></app-icon-badge>
                </div>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="notifications-recent">
                  <li><app-link (click)="setRead()" [title]="('BELLMARKASREAD' | translate)" [style]="'dropdown'">{{ ('BELLMARKASREAD' | translate) }}</app-link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
